import React, { Fragment } from "react";
import PropTypes from "prop-types";
import GridItem from "../../../layouts/utilities/grid-item";
import { hasCustomContentTantamountToContent } from "../flex-feature/utilities";
import { ConditionalWrapper } from "~/shared-components/story-card/_utilities/components";
import Warning from "~/shared-components/Warning";
import FlexFeature from "../flex-feature/default";

export const AppFallback = ({
  gridItemProps = {},
  children,
  webPreset,
  warningMessage = "App fallbacks require you to display at least one clickable piece of text (e.g. headline, label, blurb, related link, or CTA) or an image. URLs are added via the headline toolbar. Other app-only configurations are optional.",
  ...props
}) => {
  gridItemProps = {
    className: "",
    featureLabel: "App fallback",
    placementRequirements: { inMultiTableChain: true },
    ...gridItemProps
  };

  const { customFields, context: { isAdmin = false } = {} } = props;

  const webProps = {
    ...props,
    customFields: {
      ...customFields,
      ...webPreset
    }
  };

  let { previewAppFallback } = customFields;
  previewAppFallback = isAdmin && previewAppFallback;

  const warning =
    isAdmin && !hasCustomContentTantamountToContent(customFields) ? (
      <Warning message={warningMessage} />
    ) : null;

  return (
    <GridItem overrides={customFields} {...gridItemProps}>
      {warning}
      {previewAppFallback ? (
        <FlexFeature noGrid={true} {...props} />
      ) : (
        <ConditionalWrapper
          condition={webPreset}
          wrapper={() => (
            <Fragment>
              <div className="app-fallback">{children}</div>
              <FlexFeature noGrid={true} {...webProps} />
            </Fragment>
          )}
        >
          {children}
        </ConditionalWrapper>
      )}
    </GridItem>
  );
};

AppFallback.propTypes = {
  gridItemProps: PropTypes.object,
  children: PropTypes.any,
  webPreset: PropTypes.object,
  warningMessage: PropTypes.string,
  customFields: PropTypes.object,
  context: PropTypes.any
};
