/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useMemo, useState } from "react";

import { NewsletterSignUp as CartaNewsLetterSignUp } from "@washingtonpost/carta-newsletters";
import { useAppContext } from "fusion-context";
import getEnv from "~/components/utilities/env";

/* Utilities */
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { AppFallback } from "../app-fallback/default";

import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import { useChainContext } from "~/shared-components/ChainContext";

import getResizedUrl from "~/components/core/elements/image/resize-url";
import { NewsletterPropTypes } from "~/proptypes/newsletter";
import { getPropsForApp } from "./default.helpers";

const apiConfig = {
  clientApp: "homepage",
  env: ["local", "dev", "sandbox"].includes(getEnv()) ? "sandbox" : "prod"
};

const NewsletterSignUp = (props) => {
  const { customFields = {} } = props;
  const {
    customHeadline,
    customBlurb,
    customImageUrl,
    imageAlt,
    hidePromoLabel,
    promoLabel,
    buttonColor,
    hideBlurb,
    hideImage,
    newsletterContentConfig,
    isSlim
  } = customFields;

  const [hasMounted, setHasMounted] = useState(false);
  const newsletterId = newsletterContentConfig?.contentConfigValues?.id;
  const { disableLazyLoading } = useChainContext() || {};
  const lazyLoad = !disableLazyLoading;
  const { layoutVars } = useSandwichLayoutContext() || {};
  const { EditableProperty } = useAssemblerContext();
  const { metaValue } = useAppContext();

  const breakpoints = useBreakpoints();
  const bp = breakpoints.bp;
  const featureClasses =
    bp !== "" && breakpoints.xs ? "flex-column mt-lg-mod" : "";

  /* Editable Blurb */
  const editableBlurb = (
    <EditableProperty tag="span" path="blurbText" value={customBlurb} />
  );

  useEffect(() => {
    setHasMounted(true);
  }, []);

  /* For analytics */
  const location = {
    homepage: "TWP_HP",
    front: "FNT"
  };
  const contentType = metaValue("contentType");
  const pageLocation = location[contentType];

  /* Max widths for the newsletter */
  const maxW = useMemo(() => {
    let maxWidth;
    if (!isSlim) {
      maxWidth = breakpoints.xs ? 364 : 628;
    }
    if (isSlim) {
      switch (breakpoints.bp) {
        case "md":
          maxWidth = 760;
          break;
        case "lg":
        case "mx":
          maxWidth = 1024;
          break;
        default:
          // case "sm":
          maxWidth = 628;
          break;
      }
    }
    return maxWidth;
  }, [breakpoints.bp, breakpoints.xs, isSlim]);

  const smallBreakpointValue = isSlim ? 628 : 400;
  const cols = layoutVars?.[`--c-span-${bp || "mx"}`];
  const width = cols * 34 + (cols - 1) * 32;
  let initialSize = "small";
  let w = 166;
  let h = 93;

  if (width > smallBreakpointValue) {
    initialSize = "large";
    if (isSlim) {
      w = 80;
      h = 45;
    }
  }

  const resizedUrl = getResizedUrl(customImageUrl, { w: 2 * w, h: 2 * h });

  const defaultArgs = {
    imageWidth: w,
    imageHeight: h,
    promoLabel: "Daily",
    headline: customHeadline,
    body: customBlurb,
    discoverMoreLink: "https://subscribe.washingtonpost.com/newsletters/",
    isSlim: false,
    hidePromoLabel: false
  };

  const gridItemProps = {
    className: "hide-for-print",
    featureLabel: "Newsletter Signup (HP)",
    placementRequirements: isSlim
      ? { inMultiTableChain: true, cols: { min: 10 } }
      : { inMultiTableChain: true }
  };

  return (
    <AppFallback gridItemProps={gridItemProps} {...getPropsForApp(props)}>
      <div
        key={hasMounted}
        className={`ma-auto ${isSlim ? "center" : ""}`}
        style={{ maxWidth: maxW }}
      >
        <CartaNewsLetterSignUp
          {...defaultArgs}
          apiConfig={apiConfig}
          body={hideBlurb ? "" : editableBlurb}
          buttonColor={buttonColor}
          className={`${featureClasses} newsletter ${
            hideBlurb ? "hide-body" : ""
          }`}
          name={customHeadline}
          headline={customHeadline}
          hidePromoLabel={hidePromoLabel}
          rgattrId={newsletterId}
          imageAlt={hideImage ? null : imageAlt}
          imageHeight={h}
          imageUrl={hideImage ? null : resizedUrl}
          imageWidth={w}
          initialSize={initialSize}
          initiative={customHeadline}
          isSlim={isSlim}
          lazyLoad={lazyLoad}
          location={pageLocation}
          method="EI"
          promoLabel={hidePromoLabel ? "" : promoLabel || ""}
          smallBreakpointValue={smallBreakpointValue}
        />
      </div>
    </AppFallback>
  );
};

NewsletterSignUp.label = "Newsletter Signup (HP)";
NewsletterSignUp.propTypes = NewsletterPropTypes;

export default NewsletterSignUp;
