export const getPropsForApp = (props) => {
  const { customFields } = props;
  // NOTE: Native to newsletter
  const {
    customHeadline,
    signUpLink,
    customBlurb,
    customImageUrl,
    hidePromoLabel,
    promoLabel,
    hideBlurb,
    hideImage,
    isSlim
  } = customFields;

  const propsForApp = {
    ...props,
    customFields: {
      ...customFields,
      textAlignment: isSlim ? "center" : "left",
      labelShow: !hidePromoLabel,
      label: "NEWSLETTER",
      labelType: "Newsletter",
      labelUrl: signUpLink,
      labelSecondaryShow: !hidePromoLabel,
      labelSecondary: (promoLabel || "").toUpperCase(),
      labelPosition: "Default",
      labelAlignment: "inherit",
      artHide: isSlim ? true : !!hideImage,
      alternateArt: customImageUrl,
      artAspectRatio: "16:9",
      artPosition: isSlim ? "Art above head" : "Art right of head",
      artSize: "Small",
      headlineHide: isSlim ? true : !customHeadline,
      headline: isSlim ? "" : customHeadline,
      headlineAlignment: "inherit",
      blurbUrl: signUpLink, // blurbUrl is the link override field. ugh!
      blurbHide: hideBlurb,
      descriptionText: customBlurb,
      siglineHide: true,
      ctaLabelShow: true,
      ctaLabel: "Sign up",
      ctaLabelType: "Button",
      ctaLabelUrl: signUpLink,
      ctaLabelAlignment: "inherit",
      ctaLabelNameSpace: "button"
    }
  };

  return propsForApp;
};
